<template>
  <div style="text-align: center">
    <labelPDFSingle v-for="(base64, key) in base64Tracking" :key="key" :keyIndex="key" :order="order" :base64="base64" />
  </div>
</template>
<script>
import labelPDFSingle from "./LabelPDFSingle";

export default {
  name: 'LabelPDF',
  props: ['order'],
  components: {labelPDFSingle},
  computed: {
    base64Tracking() {
      let pdfData = null;
      if (this.order && this.order.last_tracking_number) {
        pdfData = this.order.last_tracking_number.label_data
      }
      if (!pdfData) return [];
      if (this.isJson(pdfData)) {
        return JSON.parse(pdfData)
      } else {
        return [pdfData]
      }
    }
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  }
}
</script>
<style scoped lang="scss">
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;

  }

  @page {
    size: A4;
    size: portrait;
  }
}
</style>
