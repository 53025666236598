<template>
  <v-card :elevation="0" class="print-order-envelop">
    <v-row no-gutters>
      <v-col cols="3">
        <b>From: </b>
      </v-col>
      <v-col cols="9">
        {{ order.customer ? order.customer.name : '' }}
      </v-col>
    </v-row>
    <v-row no-gutters v-if="order.country_id === 13">
      <v-col cols="3">
      </v-col>
      <v-col cols="9">
        76 Westminster Pw Derrimut, Vic 3026 Australia
      </v-col>
    </v-row>
    <v-row no-gutters v-else>
      <v-col cols="3">
        <b>Order No:</b>
      </v-col>
      <v-col cols="9">
        {{ order.name }}
      </v-col>
    </v-row>
    <hr style="margin: 8px 0">
    <v-row no-gutters>
      <v-col cols="3">
        <b>Ship To:</b>
      </v-col>
      <v-col cols="9">
        {{ order.receiver_name }} <br>
        {{ order.receiver_address }} <br>
        {{ order.receiver_city }} - {{ order.receiver_state }} - {{ order.receiver_post_code }} <br>
        {{ countryById(order.country_id) ? countryById(order.country_id).name : '' }}
        {{ order.receiver_country_id && countryById(order.receiver_country_id) ? countryById(order.receiver_country_id).name : '' }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        <b>Ref No:</b>
      </v-col>
      <v-col cols="8">
        {{ order.package_name }}
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'LabelEnvelop',
  props: ['order'],
  computed: {
    ...mapGetters({
      countryById: 'country/getById',
    })
  }
}
</script>
<style scoped>
.print-order-envelop {
  width: 10cm;
  height: 5cm;
  border: 1px solid black;
  padding: 15px 5px;
  line-height: 1.3;
  font-size: 0.9rem;
}

</style>
