<template>
  <div style="text-align: center;">
    <canvas :id="'the-canvas-'+keyIndex+order.id" style="display: none;"></canvas>
    <img v-if="imgBase64"
         style="padding-bottom: 10px; padding-top: 10px; display: block; margin: 0 auto; max-width: 400px; max-height: 548px;"
         :id="'image-pdf-'+keyIndex+order.id"
         :src="imgBase64"
         alt=""/>
  </div>
</template>
<script>

export default {
  name: 'LabelPDFSingle',
  props: {
    base64: {
      type: String,
      default: '',
    },
    keyIndex: {
      type: Number,
      default: 1,
    },
    order: {
      type: Object,
    },
  },
  data: () => ({
    imgBase64: null
  }),
  computed: {
  },
  mounted() {
    this.handleBase64();
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    async handleBase64() {
      let pdfData = atob(this.base64);
      let pdfjsLib = window['pdfjs-dist/build/pdf'];

      // The workerSrc property shall be specified.
      pdfjsLib.GlobalWorkerOptions.workerSrc = '/webapp/pdf.worker.js';

      // Using DocumentInitParameters object to load binary data.
      let loadingTask = pdfjsLib.getDocument({data: pdfData});
      let that = this;
      await new Promise(resolve => setTimeout(resolve, 100));

      loadingTask.promise.then(function(pdf) {
        console.log('PDF loaded');

        // Fetch the first page
        let pageNumber = 1;
        pdf.getPage(pageNumber).then(async (page) => {
          console.log('Page loaded');
          let scale = 1.3;
          let viewport = page.getViewport({scale: scale});

          // Prepare canvas using PDF page dimensions
          await new Promise(resolve => setTimeout(resolve, 300));
          let canvas = document.getElementById('the-canvas-'+that.keyIndex+that.order.id);
          let context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render PDF page into canvas context
          let renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          let renderTask = page.render(renderContext);
          renderTask.promise.then(function () {
            console.log('Page rendered');
          });
          await that.convertCanvasToImage();
        });
      }, function (reason) {
        // PDF loading error
        console.error(reason);
      });
    },
    async convertCanvasToImage() {
      await new Promise(resolve => setTimeout(resolve, 200));
      const canvas = document.getElementById('the-canvas-'+this.keyIndex+this.order.id)
      const img    = canvas.toDataURL('image/png')
      this.imgBase64 = img;
      document.getElementById('the-canvas-'+this.keyIndex+this.order.id).remove();
    }
  }
}
</script>
<style scoped lang="scss">
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;

  }

  @page {
    size: A4;
    size: portrait;
  }
}
</style>
