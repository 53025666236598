<template>
  <div style="text-align: center; position: relative">
    <div class="download-layer">
      <v-icon class="mr-2 download-btn" color="success" @click="downloadPdf">
        mdi-download
      </v-icon>
    </div>
    <img
      style="padding-bottom: 10px; padding-top: 10px; display: block; margin: 0 auto; max-width: 400px; max-height: 548px;"
      v-for="(imgBase64, key) in base64ImageTracking"
      :key="key" :src="'data:image/png;base64,' + imgBase64" alt="">
  </div>
</template>
<script>

import {mapActions} from "vuex";
import FileSaver from 'file-saver';

export default {
  name: 'LabelImage',
  props: ['order'],
  computed: {
    base64ImageTracking() {
      let imgBase64 = null;
      if (this.order && this.order.last_tracking_number) {
        imgBase64 = this.order.last_tracking_number.label_data
      }
      if (!imgBase64) return [];
      if (this.isJson(imgBase64)) {
        return JSON.parse(imgBase64)
      } else {
        return [imgBase64]
      }
    }
  },
  methods: {
    ...mapActions({
      getSingleLabel: 'order/getSingleLabel',
    }),
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    downloadPdf() {
      this.getSingleLabel({trackingNumber: this.order.tracking_number, type: 'download-pdf'}).then(res => {
        let fileName = res.headers && res.headers['content-disposition'] ? res.headers['content-disposition'].replace('attachment; filename=', '') : null;
        if (!fileName) fileName = 'label.pdf';
        FileSaver.saveAs(res.data, fileName);
      }).catch(async err => {
        let errData = await err.response.data.text()
        errData = JSON.parse(errData) || {}
        let message = errData.message
        if(message) this.noticeError(message.toString())
      })
    }
  }
}
</script>
<style scoped lang="scss">
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;

  }

  @page {
    size: A4;
    size: portrait;
  }

  .download-layer {
    display: none;
  }
}

.download-layer {
  position: absolute;
  top: 0;
  left: calc(50% - 200px);
  width: 400px;
  margin: auto;
  bottom: 0;

  .download-btn {
    display: none;
    font-size: 64px;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
  }

  &:hover {
    background-color: rgb(0, 0, 0, 0.33);

    > .download-btn {
      display: block !important;
      cursor: pointer;
    }
  }


}
</style>
